import React from "react";

import { Typography, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled(`div`)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
  "& > div": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const Left = styled(`div`)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Right = styled(`div`)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Seperator = styled(`div`)(({ theme }) => ({
  width: "2px",
  flexGrow: 1,
  backgroundColor: theme.palette.grey[400],
}));

interface PCTimelineItemProps {
  name: string;
  tags: string[];
}

const PCTimelineItem: React.FC<PCTimelineItemProps> = ({
  name,
  tags,
  children,
}) => {
  return (
    <Root>
      <Left>
        <Typography variant="body2" color="textSecondary">
          {name}
        </Typography>
        <Seperator />
      </Left>
      <Right>
        <Typography variant="body2" component="p">
          {children}
        </Typography>
        {tags.map((name, j) => (
          <Chip key={j} sx={{ m: 0.5 }} variant="outlined" label={name} />
        ))}
      </Right>
    </Root>
  );
};

export default PCTimelineItem;
