import * as React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CurveEditorInstructions: React.FC = () => {
  return (
    <Paper>
      <List
        dense={true}
        subheader={<ListSubheader>Current Functionality</ListSubheader>}
      >
        <ListItem>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText primary="Switch between curve/spline algorithms" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText primary="Add / Remove / Move points" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText primary="Show / Hide shells" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText primary="Clear all control points" />
        </ListItem>
      </List>
    </Paper>
  );
};

export default CurveEditorInstructions;
