import { CurveEditorPoint, ComputeCurveResult } from "./types";

export const computeNLI = (
  controlPoints: readonly CurveEditorPoint[],
  sliderT: number,
  interpolationStep: number,
): ComputeCurveResult => {
  if (controlPoints.length < 3) {
    return { points: [], shells: [] };
  }

  const points: CurveEditorPoint[] = [];

  interpolationStep = Math.max(0.001, interpolationStep);
  for (let t = 0; t <= 1 + interpolationStep; t += interpolationStep) {
    //0.005
    const prevT = t;
    t = Math.min(1, t);

    points.push(generateNLI(controlPoints, t));

    t = prevT;
  }

  const shells = getShells(controlPoints, sliderT);

  return { points, shells };
};

const generateNLI = (
  points: readonly CurveEditorPoint[],
  t: number,
): CurveEditorPoint => {
  if (points.length === 1) {
    return points[0];
  }

  const tempPoints: CurveEditorPoint[] = [];
  for (let i = 1; i < points.length; ++i) {
    tempPoints.push({
      x: (1.0 - t) * points[i - 1].x + t * points[i].x,
      y: (1.0 - t) * points[i - 1].y + t * points[i].y,
    });
  }

  return generateNLI(tempPoints, t);
};

const getShells = (points: readonly CurveEditorPoint[], t: number) => {
  const shells: CurveEditorPoint[][] = [];

  const generatePointsAndShells = (
    points: readonly CurveEditorPoint[],
    t: number,
  ): void => {
    if (points.length === 1) {
      return;
    }

    const tempPoints: CurveEditorPoint[] = [];
    for (let i = 1; i < points.length; ++i) {
      tempPoints.push({
        x: (1.0 - t) * points[i - 1].x + t * points[i].x,
        y: (1.0 - t) * points[i - 1].y + t * points[i].y,
      });
    }

    shells.push(tempPoints);

    generatePointsAndShells(tempPoints, t);
  };

  generatePointsAndShells(points, t);

  return shells;
};
