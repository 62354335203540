import * as React from "react";
import {
  Slider,
  Switch,
  FormControlLabel,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  Divider,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { computeNLI } from "./nli";
import { computeBernsteinPolynomial } from "./bernstein-polynomial";
import { CurveEditorPoint } from "./types";
import { red } from "@mui/material/colors";
import { computeMidpoint } from "./midpoint";
import CurveEditorInstructions from "./instructions";
import { computeCubicSpline } from "./cubic-spline";

enum AlgorithmType {
  NLI = "NLI",
  CubicSpline = "Cubic Spline",
  BernsteinPolynomial = "Bernstein Polynomial",
  Midpoint = "Midpoint",
}

const StyledSvg = styled(`svg`)(({ theme }) => ({
  border: `1px solid black`,
}));

const ButtonContainer = styled(`div`)(({ theme }) => ({
  "& > .MuiButton-root": {
    margin: theme.spacing(1),
  },
}));

const OptionsContainer = styled(`div`)(({ theme }) => ({
  "& > div": {
    margin: theme.spacing(2),
  },
}));

const ContainerSpaceBetween = styled(`div`)(({ theme }) => ({
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
}));

const ShellControlsContainer = styled(`div`)(({ theme }) => ({
  display: `flex`,
  alignItems: `center`,
}));

const generatePath = (points: CurveEditorPoint[]): string => {
  let path = ``;
  for (let i = 1; i < points.length; ++i) {
    path += `M${points[i - 1].x},${points[i - 1].y}L${points[i].x},${
      points[i].y
    }`;
  }
  return path;
};

const generateShellsPath = (shells: CurveEditorPoint[][]): string => {
  let path = ``;
  for (let i = 0; i < shells.length; ++i) {
    for (let j = 1; j < shells[i].length; ++j) {
      path += `M${shells[i][j - 1].x},${shells[i][j - 1].y}L${shells[i][j].x},${
        shells[i][j].y
      }`;
    }
  }
  return path;
};

const CurveEditor: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const svgRef = useRef<SVGSVGElement>(null);
  const [clientRect, setClientRect] = useState<DOMRect>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    x: 0,
    y: 0,
    toJSON: () => "",
  });

  const [selectedControlPointIndex, setSelectedControlPointIndex] =
    useState(-1);
  const [controlPoints, setControlPoints] = useState<CurveEditorPoint[]>([]);
  const [linePoints, setLinePoints] = useState<CurveEditorPoint[]>([]);

  const [shells, setShells] = useState<CurveEditorPoint[][]>([]);
  const [showShells, setShowShells] = useState(true);

  const defaultSliderTValue = 0.5;
  const [sliderT, setSliderT] = useState(defaultSliderTValue);
  const [sliderInterpolationStep, setSliderInterpolationStep] = useState(0.02);

  const [algorithm, setAlgorithm] = useState(AlgorithmType.NLI);
  const [showControlPoints, setShowControlPoints] = useState(true);
  const [showControlPointLines, setShowControlPointLines] = useState(true);

  const svgHeight = 600;
  const svgWidth = 800;

  const controlPointRadius = 6;
  const shellPointRadius = 4;

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<SVGSVGElement>) => {
      const scaleX = svgWidth / clientRect.width;
      const scaleY = svgHeight / clientRect.height;
      const x = Math.round((e.clientX - clientRect.left) * scaleX);
      const y = Math.round((e.clientY - clientRect.top) * scaleY);
      setMousePosition({
        x: x,
        y: y,
      });

      if (selectedControlPointIndex !== -1) {
        setControlPoints((points) => {
          const cps = [...points];
          cps[selectedControlPointIndex] = { x: x, y: y };
          return cps;
        });
      }
    },
    [
      clientRect.height,
      clientRect.left,
      clientRect.top,
      clientRect.width,
      selectedControlPointIndex,
    ],
  );

  const handleMouseDown = () => {
    let collision = false;
    for (let i = 0; i < controlPoints.length; ++i) {
      if (
        pointCircleCheck(mousePosition, controlPoints[i], controlPointRadius)
      ) {
        collision = true;
        setSelectedControlPointIndex(i);
        break;
      }
    }
    if (!collision) {
      setControlPoints((points) => [
        ...points,
        { x: mousePosition.x, y: mousePosition.y },
      ]);
    }
  };

  const handleMouseUp = () => {
    setSelectedControlPointIndex(-1);
  };

  const handleSliderChange = (e: Event, value: number | number[]) => {
    const val = Array.isArray(value) ? value[0] : value;
    setSliderT(val);
  };

  const handleSliderInterpolationStepChange = (
    e: Event,
    value: number | number[],
  ) => {
    const val = Array.isArray(value) ? value[0] : value;
    setSliderInterpolationStep(val);
  };

  const handleShowShells = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowShells(e.target.checked);
  };

  const handleShowControlPoints = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowControlPoints(e.target.checked);
  };

  const handleShowControlPointLines = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowControlPointLines(e.target.checked);
  };

  const handleAlgorithmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as AlgorithmType;
    setAlgorithm(val);
  };

  const handleClear = () => {
    setControlPoints([]);
    setLinePoints([]);
    setShells([]);
  };

  const handleRemoveLastPoint = () => {
    if (controlPoints.length > 0) {
      setControlPoints(controlPoints.slice(0, controlPoints.length - 1));
    }
  };

  const memoizedCallAlgorithm = useCallback(
    (al: AlgorithmType, cp: CurveEditorPoint[], st: number, sis: number) => {
      let s: CurveEditorPoint[][] = [];
      let lp: CurveEditorPoint[] = [];

      if (al == AlgorithmType.NLI) {
        const { points, shells } = computeNLI(cp, st, sis);
        lp = points;
        s = shells;
      } else if (al == AlgorithmType.CubicSpline) {
        lp = computeCubicSpline(cp);
      } else if (al == AlgorithmType.BernsteinPolynomial) {
        lp = computeBernsteinPolynomial(cp, sis);
      } else if (al == AlgorithmType.Midpoint) {
        const { points, shells } = computeMidpoint(cp);
        lp = points;
        s = shells;
      }

      setLinePoints(lp);
      setShells(s);
    },
    [],
  );

  //const debouncedControlPoints = useDebounce(controlPoints, 10);

  useEffect(() => {
    if (svgRef !== null && svgRef.current !== null) {
      setClientRect(svgRef.current.getBoundingClientRect());
    }

    if (!controlPoints) {
      return;
    }

    memoizedCallAlgorithm(
      algorithm,
      controlPoints,
      sliderT,
      sliderInterpolationStep,
    );
  }, [
    memoizedCallAlgorithm,
    algorithm,
    sliderT,
    showShells,
    controlPoints,
    sliderInterpolationStep,
  ]);

  let shellsLinesPath = ``;
  if (shells && showShells) {
    shellsLinesPath = generateShellsPath(shells);
  }

  let controlPointsPath = ``;
  if (showControlPoints && showControlPointLines) {
    controlPointsPath = generatePath(controlPoints);
  }

  const linePointsPath = generatePath(linePoints);

  return (
    <>
      <CurveEditorInstructions />
      <br />
      <StyledSvg
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        ref={svgRef}
      >
        <defs>
          <circle
            id="c1"
            r={controlPointRadius}
            stroke="#455a64"
            strokeWidth={1}
            fill="#90ee90"
            cx={0}
            cy={0}
          />
          <circle
            id="c2"
            r={shellPointRadius}
            stroke="#455a64"
            strokeWidth={1}
            fill="#eeee90"
            cx={0}
            cy={0}
          />
        </defs>

        <g>
          <path d={linePointsPath} fill="none" stroke="#000" strokeWidth={2} />
        </g>

        <g>
          {showControlPoints && showControlPointLines && (
            <path d={controlPointsPath} fill="none" stroke="#000" />
          )}
        </g>

        {shells && shells.length > 0 && showShells && (
          <g>
            <path d={shellsLinesPath} fill="none" stroke="#979797" />

            {shells.map((list, i) => {
              const groupKey = `shellGroup${i}`;
              return (
                <g key={groupKey}>
                  {list.map((cp, j) => {
                    const key = `shell${j},${cp.x},${cp.y}`;
                    return <use key={key} href="#c2" x={cp.x} y={cp.y} />;
                  })}
                </g>
              );
            })}
          </g>
        )}

        <g>
          {showControlPoints &&
            controlPoints.map((cp) => {
              const key = `${cp.x},${cp.y}`;
              return <use key={key} href="#c1" x={cp.x} y={cp.y} />;
            })}
        </g>
      </StyledSvg>
      <Typography paragraph>
        Mouse Position: {mousePosition.x}, {mousePosition.y}
      </Typography>
      <OptionsContainer>
        <ContainerSpaceBetween>
          <div>
            <FormLabel component="legend">Algorithm</FormLabel>
            <RadioGroup row value={algorithm} onChange={handleAlgorithmChange}>
              <FormControlLabel
                value={AlgorithmType.NLI}
                control={<Radio />}
                label={AlgorithmType.NLI}
              />
              <FormControlLabel
                value={AlgorithmType.BernsteinPolynomial}
                control={<Radio />}
                label={AlgorithmType.BernsteinPolynomial}
              />
              <FormControlLabel
                value={AlgorithmType.Midpoint}
                control={<Radio />}
                label={AlgorithmType.Midpoint}
              />
              {/* <FormControlLabel
                value={AlgorithmType.CubicSpline}
                control={<Radio />}
                label={AlgorithmType.CubicSpline}
              /> */}
            </RadioGroup>
          </div>
          <ButtonContainer>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              onClick={handleRemoveLastPoint}
              disabled={controlPoints.length < 1}
            >
              Remove Last Point
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteIcon />}
              onClick={handleClear}
              disabled={controlPoints === null || controlPoints.length < 1}
            >
              Clear
            </Button>
          </ButtonContainer>
        </ContainerSpaceBetween>
        <Divider />
        <div>
          <FormLabel component="legend">Options</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={showControlPoints}
                onChange={handleShowControlPoints}
                color="primary"
              />
            }
            label="Show Control Points"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showControlPointLines}
                onChange={handleShowControlPointLines}
                color="primary"
                disabled={!showControlPoints}
              />
            }
            label="Show Control Point Lines"
          />
          <br />
          <br />
          <FormLabel component="legend">Interpolation Step</FormLabel>
          <Slider
            value={sliderInterpolationStep}
            step={0.005}
            marks
            min={0.005}
            max={0.1}
            valueLabelDisplay="auto"
            onChange={handleSliderInterpolationStepChange}
          />
        </div>
        <Divider />
        <div>
          <FormLabel component="legend">Shell Options</FormLabel>
          <ShellControlsContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={showShells}
                  onChange={handleShowShells}
                  color="primary"
                  disabled={shells === null || shells.length < 1}
                />
              }
              label="Show Shells"
            />
            <Slider
              value={sliderT}
              step={0.1}
              marks
              min={0}
              max={1}
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
              disabled={!showShells || shells === null || shells.length < 1}
              sx={{ flex: `2` }}
            />
          </ShellControlsContainer>
        </div>
      </OptionsContainer>
    </>
  );
};

const pointCircleCheck = (
  point: CurveEditorPoint,
  circleCenter: CurveEditorPoint,
  radius: number,
) => {
  const distX = point.x - circleCenter.x;
  const distY = point.y - circleCenter.y;
  const distance = Math.sqrt(distX * distX + distY * distY);
  return distance <= radius;
};

const debounce = (callback: () => void, ms: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return () => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      //console.log("called");
      callback();
    }, ms);
  };
};

function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export default CurveEditor;
