import { ComputeCurveResult, CurveEditorPoint } from "./types";

export const computeMidpoint = (
  controlPoints: readonly CurveEditorPoint[],
): ComputeCurveResult => {
  const size = controlPoints.length;
  if (size < 2) {
    return { points: [], shells: [] };
  }

  const midpointResult = midpoint(controlPoints);
  const firstPoints: CurveEditorPoint[][] = [];
  firstPoints.push([...controlPoints]);

  for (let i = midpointResult.length - 1; i >= 0; i--) {
    firstPoints.push(midpointResult[i]);
  }

  const points: CurveEditorPoint[] = [];
  const shells: CurveEditorPoint[][] = [];

  const midpointPoints = (vec: readonly CurveEditorPoint[][], k: number) => {
    if (!k) {
      return;
    }

    shells.push(generatePoints(vec));

    const left = doLeft(vec);
    midpointPoints(left, k - 1);

    if (k === 1) {
      points.push(...generatePoints(left));
    }

    const right = doRight(vec);
    midpointPoints(right, k - 1);

    if (k === 1) {
      points.push(...generatePoints(right));
    }
  };

  midpointPoints(firstPoints, 3);

  return { points, shells };
};

const doLeft = (vec: readonly CurveEditorPoint[][]): CurveEditorPoint[][] => {
  const vecPass: CurveEditorPoint[] = [];

  for (let i = 0; i < vec.length; i++) {
    vecPass.push(vec[i][0]);
  }

  const result: CurveEditorPoint[][] = [];
  result.push(vecPass);

  const midpointResult = midpoint(vecPass);

  // reversing the midpointResult and putting it in result
  for (let i = midpointResult.length - 1; i >= 0; i--) {
    result.push(midpointResult[i]);
  }

  return result;
};

const doRight = (vec: readonly CurveEditorPoint[][]): CurveEditorPoint[][] => {
  const vecPass: CurveEditorPoint[] = [];

  for (let i = vec.length - 1; i >= 0; i--) {
    const c = vec[i].length - 1;
    vecPass.push(vec[i][c]);
  }

  const result: CurveEditorPoint[][] = [];
  result.push(vecPass);

  const midpointResult = midpoint(vecPass);

  // reversing the midpointResult and putting it in result
  for (let i = midpointResult.length - 1; i >= 0; i--) {
    result.push(midpointResult[i]);
  }

  return result;
};

const generatePoints = (vec: readonly CurveEditorPoint[][]) => {
  const result: CurveEditorPoint[] = [];
  for (let i = 0; i < vec.length; ++i) {
    result.push(vec[i][0]);
  }

  for (let i = vec.length - 1; i >= 0; --i) {
    const c = vec[i].length - 1;
    result.push(vec[i][c]);
  }

  return result;
};

const midpoint = (
  points: readonly CurveEditorPoint[],
): CurveEditorPoint[][] => {
  if (points.length == 1) {
    return [];
  }

  const tempPoints: CurveEditorPoint[] = [];
  for (let i = 1; i < points.length; i++) {
    tempPoints.push({
      x: 0.5 * points[i - 1].x + 0.5 * points[i].x,
      y: 0.5 * points[i - 1].y + 0.5 * points[i].y,
    });
  }

  const result = midpoint(tempPoints);
  result.push(tempPoints);

  return result;
};
