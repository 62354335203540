import React from "react";

import { styled } from "@mui/material/styles";

const Root = styled(`div`)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const PCTimeline: React.FC = ({ children }) => {
  return <Root>{children}</Root>;
};

export default PCTimeline;
