import { CurveEditorPoint } from "./types";

export const computeCubicSpline = (
  controlPoints: readonly CurveEditorPoint[],
): CurveEditorPoint[] => {
  const size = controlPoints.length;
  if (size < 3) {
    return [];
  }

  const result: CurveEditorPoint[] = [];
  const lastColumn = createMatrix(controlPoints);

  for (let t = 0; t <= size - 1; t += 0.01) {
    const point = { ...lastColumn[0] };
    let appendValue = t;

    for (let i = 1; i < 4; i++) {
      point.x += lastColumn[i].x * appendValue;
      point.y += lastColumn[i].y * appendValue;
      appendValue *= t;
    }

    for (let k = 1; k <= size - 2; k++) {
      appendValue = (t - k) * (t - k) * (t - k);
      if (t < k) {
        appendValue = 0;
      }

      point.x += lastColumn[k + 3].x * appendValue;
      point.y += lastColumn[k + 3].y * appendValue;
    }

    result.push(point);
  }

  return result;
};

const divideRow = (row: CurveEditorPoint[], d: CurveEditorPoint) => {
  for (let i = 0; i < row.length; i++) {
    if (d.x != 0) {
      row[i].x /= d.x;
    }

    if (d.y != 0) {
      row[i].y /= d.y;
    }
  }
};

const subtractRows = (
  row1: CurveEditorPoint[],
  row2: CurveEditorPoint[],
  val: CurveEditorPoint,
) => {
  for (let i = 0; i < row1.length; i++) {
    row2[i].x -= row1[i].x * val.x;
    row2[i].y -= row1[i].y * val.y;
  }
};

// Using Gauss-Jordan Elimination
const eliminate = (matrix: CurveEditorPoint[][], rows: number) => {
  for (let i = 0; i < rows; i++) {
    divideRow(matrix[i], matrix[i][i]);

    // Make zeros below diagonal
    for (let k = i + 1; k < rows; k++) {
      subtractRows(matrix[i], matrix[k], matrix[k][i]);
    }
  }

  for (let i = rows - 1; i >= 0; i--) {
    // Make zeros above diagonal
    for (let k = 0; k < i; k++) {
      subtractRows(matrix[i], matrix[k], matrix[k][i]);
    }
  }
};

const createMatrix = (controlPoints: readonly CurveEditorPoint[]) => {
  const matrix: CurveEditorPoint[][] = [];
  const size = controlPoints.length;

  for (let i = 0; i < size; i++) {
    const rowPointList: CurveEditorPoint[] = [];
    let appendValue = 1;

    for (let j = 0; j < 4; j++) {
      rowPointList.push({ x: appendValue, y: appendValue });
      appendValue *= i;
    }

    for (let k = 1; k <= size - 2; k++) {
      let val = 0;
      if (i > k) {
        val = (i - k) * (i - k) * (i - k);
      }

      rowPointList.push({ x: val, y: val });
    }

    rowPointList.push({ ...controlPoints[i] });
    matrix.push(rowPointList);
  }

  // Bottom 2 rows

  const secondToLastRow: CurveEditorPoint[] = [];
  secondToLastRow.push({ x: 0, y: 0 });
  secondToLastRow.push({ x: 0, y: 0 });
  secondToLastRow.push({ x: 2, y: 2 });

  for (let i = 1; i <= size; i++) {
    secondToLastRow.push({ x: 0, y: 0 });
  }

  matrix.push(secondToLastRow);

  // Last Row
  const lastRow: CurveEditorPoint[] = [];
  lastRow.push({ x: 0, y: 0 });
  lastRow.push({ x: 0, y: 0 });
  lastRow.push({ x: 2, y: 2 });
  lastRow.push({ x: 6 * (size - 1), y: 6 * (size - 1) });

  for (let i = 1; i <= size - 2; i++) {
    let val = 0;
    if (size - 1 > i) {
      val = 6 * (size - 1 - i);
    }

    lastRow.push({ x: val, y: val });
  }

  lastRow.push({ x: 0, y: 0 });
  matrix.push(lastRow);

  eliminate(matrix, size + 2);

  const lastColumn: CurveEditorPoint[] = [];
  for (let i = 0; i < matrix.length; i++) {
    lastColumn.push(matrix[i][matrix[i].length - 1]);
  }

  return lastColumn;
};
