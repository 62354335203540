import { CurveEditorPoint } from "./types";

export const computeBernsteinPolynomial = (
  controlPoints: readonly CurveEditorPoint[],
  interpolationStep: number,
): CurveEditorPoint[] => {
  const size = controlPoints.length;
  if (size < 1) {
    return [];
  }

  const result: CurveEditorPoint[] = [];

  interpolationStep = Math.max(0.001, interpolationStep);
  for (let t = 0; t <= 1 + interpolationStep; t += interpolationStep) {
    const prevT = t;
    t = Math.min(1, t);

    const p: CurveEditorPoint = { x: 0, y: 0 };
    for (let i = 0; i < size; i++) {
      const bernstein =
        pascalsTriangle[size - 1][i] *
        Math.pow(1 - t, size - 1 - i) *
        Math.pow(t, i);

      p.x += controlPoints[i].x * bernstein;
      p.y += controlPoints[i].y * bernstein;
    }

    result.push(p);
    t = prevT;
  }

  return result;
};

const calculatePascalsTriangle = () => {
  const result = [];
  for (let i = 0; i < 100; ++i) {
    result.push(calculatePascalsTriangleRow(i));
  }
  return result;
};

const calculatePascalsTriangleRow = (n: number) => {
  const row = new Array(n + 1);
  for (let i = 0; i < row.length; ++i) {
    row[i] = 0;
  }

  row[0] = 1;

  for (let j = 1; j <= n; ++j) {
    for (let i = j; i; i--) {
      row[i] += row[i - 1];
    }
  }

  return row;
};

const pascalsTriangle = calculatePascalsTriangle();
